<template>
  <footer class="bg-white iq-footer mx-0 main-footer">
    <b-container>
      <b-row class="pt-4">
        <b-col xl="4" md="12">
          <p
            class="font-size-18 font-weight-bold text-primary mt-0 mt-0 position-relative header-website-footer mb-3"
          >
            {{ $t("main.about_market") }}
          </p>
          <p class="text-primary font-size-20 brief">
            {{ $t("endUser.footer") }}
          </p>
          <p
            class="font-size-18 font-weight-bold mt-0 mt-0 position-relative header-website-footer mb-3 text-black-footer"
          >
            {{ $t("main.download_app") }}
          </p>
          <div class="hold-app-store">
            <a
              :href="appSettings.google_store_link"
              target="_blank"
              class="app-store"
            >
              <div class="item">
                <p class="font-size-14 font-weight-normal">GET IT IN</p>
                <p class="font-size-18 font-weight-bolder">Google Play</p>
              </div>
              <img
                src="@/assets/images/icons/google-play.svg"
                alt="google play"
                title="google play"
                class="w-[50px]"
              />
            </a>
            <a
              :href="appSettings.apple_store_link"
              target="_blank"
              class="app-store"
            >
              <div class="item">
                <p class="font-size-14 font-weight-normal">Download on the</p>
                <p class="font-size-18 font-weight-bolder">App Store</p>
              </div>
              <img
                src="@/assets/images/icons/apple-store.svg"
                alt="google play"
                title="google play"
                class="w-[50px]"
              />
            </a>
          </div>
        </b-col>

        <b-col
          :xl="$i18n.locale === 'ar' ? 3 : 2"
          md="4"
          sm="6"
          class="d-flex justify-content-center"
        >
          <div class="fix-width">
            <p
              class="font-size-18 font-weight-bold mt-0 mt-0 position-relative header-website-footer mb-3 text-black-footer"
            >
              {{ $t("main.important_links") }}
            </p>
            <ul class="p-0 font-size-16 footer-menu" style="gap: 20px">
              <router-link
                :to="{ name: 'aboutUs' }"
                tag="a"
                class="text-black-footer"
                >{{ $t("endUser.aboutUs") }}
              </router-link>
              <router-link
                :to="{ name: 'contactUs' }"
                tag="a"
                class="text-black-footer"
                >{{ $t("endUser.contactUs") }}
              </router-link>
              <router-link
                :to="{ name: 'registrationProvider' }"
                tag="a"
                class="text-black-footer"
              >
                {{ $t("joining.joiningServices") }}
              </router-link>
            </ul>
          </div>
        </b-col>
        <b-col
          :xl="$i18n.locale === 'ar' ? 2 : 3"
          md="4"
          sm="6"
          class="d-flex justify-content-center"
        >
          <div class="fix-width">
            <p
              class="font-size-18 font-weight-bold mt-0 mt-0 position-relative header-website-footer mb-3 text-black-footer"
            >
              {{ $t("endUser.termsConditions") }}
            </p>
            <ul class="p-0 font-size-16 footer-menu" style="gap: 20px">
              <router-link
                :to="{ name: 'termsCondition' }"
                tag="a"
                class="text-black-footer"
              >
                {{ $t("endUser.termsCondition") }}
              </router-link>
              <router-link
                :to="{ name: 'privacyPolicy' }"
                tag="a"
                class="text-black-footer"
              >
                {{ $t("endUser.privacyPolicy") }}
              </router-link>
            </ul>
          </div>
        </b-col>
        <b-col xl="3" md="4" class="d-flex justify-content-center">
          <div class="social-laptop">
            <p
              class="font-size-18 font-weight-bold mt-0 position-relative header-website-footer mb-3 text-black-footer"
            >
              {{ $t("main.social_accounts") }}
            </p>
            <ul class="d-flex p-0 font-size-24" style="gap: 20px">
              <li class="social">
                <a :href="appSettings?.sociallinks?.facebook" target="_blank"
                  ><i class="lab la-facebook-f text-black-footer"></i
                ></a>
              </li>
              <li class="social">
                <a :href="appSettings?.sociallinks?.twitter" target="_blank"
                  ><i class="lab la-twitter text-black-footer"></i
                ></a>
              </li>
              <li class="social">
                <a :href="appSettings?.sociallinks?.instagram" target="_blank"
                  ><i class="lab la-instagram text-black-footer"></i
                ></a>
              </li>
              <li class="social">
                <a :href="appSettings?.sociallinks?.snapschat" target="_blank"
                  ><i class="lab la-snapchat text-black-footer"></i
                ></a>
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!--Copyright-->
    <b-container>
      <div class="d-flex justify-content-center copyrightsFooter">
        <p class="m-0">{{ $t("endUser.reserved") }} {{ currentYear }}</p>
      </div>
    </b-container>
    <!--/.Copyright-->
  </footer>
</template>
<script>
export default {
  data() {
    return {
      footerImage: require("@/assets/images/ALATTARH-LOGO.png"),
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    appSettings() {
      return this.$store.getters["Setting/appSettings"];
    },
  },
};
</script>

<style lang="scss">
.fix-width {
  width: 60%;
  margin: 0 auto;

  &:last-of-type {
    width: 80%;
    @media (max-width: 1300px) {
      width: 100%;
    }
  }

  @media (max-width: 1300px) {
    width: 100%;
  }
}

.text-black-footer {
  color: #282828;
}

.header-website-footer {
  padding-bottom: 0.5rem;
  @media (max-width: 1400px) {
    font-size: 1rem !important;
  }
}

.social-laptop {
  @media (max-width: 1400px) {
    width: 100%;
  }
}

.social {
  i {
    transition: 0.2s all ease-in-out;
  }

  &:hover {
    a {
      i {
        color: var(--iq-warning);
        cursor: pointer;
      }
    }
  }
}

.hold-app-store {
  display: flex;
  margin-bottom: 2.5rem;
  .app-store {
    display: flex;
    align-items: center;
    border: 1px solid #030404;
    padding: 0.3rem 0.7rem;
    border-radius: 8px;
    &:first-of-type {
      margin-left: 1rem;
      //@media (max-width: 992px) {
      //  margin-left: 0;
      //}
      &:lang(en) {
        margin-left: 0;
        margin-right: 1rem;
      }
    }
    .item {
      text-align: left;
      margin-left: 0.5rem;
      p {
        color: #000000;
        margin: 0;

        &:last-of-type {
          margin-top: -10px;
        }
      }
      &:last-of-type {
        p {
          &:first-of-type {
            font-size: 10px !important;
          }
        }
      }
      &:lang(en) {
        margin-left: 0;
        margin-right: 0.5rem;
      }
    }
    img {
      width: 30px;
    }
    @media (max-width: 992px) {
      margin-bottom: 0.5rem;
      justify-content: center;
    }
  }
  //@media (max-width: 992px) {
  //  display: block;
  //}
}

.brief {
  text-align: right;
  &:lang(en) {
    text-align: left;
  }
}
.footer-menu a {
  display: block;
  position: relative;
  padding-right: 1rem;
  //&:before{
  //  content: "";
  //  width: 7px;
  //  height: 7px;
  //  background: #000;
  //  border-radius: 50%;
  //  position: absolute;
  //  top: 50%;
  //  right: 0;
  //  transform: translateY(-50%);
  //  &:lang(en){
  //    display: none !important;
  //  }
  //}
  //&:lang(en){
  //  padding-right: 0;
  //}
}
@media (max-width: 979px) {
  .main-footer {
    padding-bottom: 20px !important;
  }
}
</style>
