<template>
  <!-- :placeholder="$t('endUser.storeType')" -->
  <div
    class="position-relative d-flex align-items-center border search-wrapper w-80"
  >
    <div class="d-flex align-items-center search-wrapper flex-grow-1">
      <div class="inputContainer d-flex align-items-center flex-grow-1">
        <b-input
          :placeholder="$t('main.search')"
          class="bg-transparent border-0 flex-grow-1 min-width-5 text-muted"
          v-model="search"
          @focus="onFocus"
          @input="handleChange"
        ></b-input>
        <i class="las la-search text-gray font-size-20"></i>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/main.js";
import { debounce } from "debounce";
import commonMixin from "@/mixins/commonMixin";
export default {
  mixins: [commonMixin],
  data() {
    return {
      searchLoading: false,
      search: "",
      isFocused: false,

      searchResult: [],
      advancedSearch: false,
    };
  },
  methods: {
    onFocus() {
      this.isFocused = true;
    },
    removeRecentSearch(id) {
      this.recentSearch = this.recentSearch.filter((item) => item.id !== id);
      localStorage.setItem("searchedItems", JSON.stringify(this.recentSearch));
    },

    closeSearch() {
      this.search = "";
      this.isFocused = false;
      this.advancedSearch = false;
    },
    debounceApi: debounce((search, that) => {
      that.$router.push({
        name: "products-search",
        query: { search: that.search },
      });
    }, 500),
    handleChange(value) {
      this.searchLoading = true;
      const { search } = this.$route.query;
      this.debounceApi(search, this);
    },
  },
  created() {
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(".search-wrapper") &&
        !e.target.classList.contains("icon")
      ) {
        this.isFocused = false;
      }
    });
  },
  watch: {
    "$route.query.search": {
      handler(value) {
        this.search = value;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.w-80 {
  width: 20% !important;
}
.search-wrapper {
  background-color: #fff;
  border-radius: 4px;
  max-width: 750px;
  .vs__dropdown-menu {
    left: auto !important;
    right: 0 !important;
    top: calc(100% + 5px);
    // border-start-start-radius: 20px;
  }
  .vs__dropdown-toggle {
    border: none !important;
    border-inline-end: 1px solid rgba(0 0 0 / 0.2) !important;
    background-color: transparent;
    border-radius: 0 !important;
    min-width: 120px;
    color: #333;
    padding: 0 10px 0 5px;
  }

  .vs__actions {
    line-height: 0;
  }

  .inputContainer {
    padding: 0 15px;

    .form-control {
      padding: 0;
    }
  }
  .search-listing {
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    position: absolute;
    top: calc(100% + 5px);
    width: 100%;
    left: 0;
    background-color: #fff;
    border-radius: 4px;
    color: #333;
    padding: 20px;
    z-index: 99;

    .search-listing-header {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0 0 0 / 0.2);
      .advanced-search {
        color: #9d9d9d;
        .icon {
          width: 35px;
          height: 35px;
          border-radius: 8px;
          background: #707070;
          font-size: 20px;
          color: #fff;
          font-size: 20px;
          margin-inline-end: 10px;
        }
      }
      .back-btn {
        color: #9d9d9d;
        .icon {
          font-size: 25px;
        }
      }
    }

    .search-title {
      color: #9d9d9d;
      margin-bottom: 20px;
    }

    .search-listing-recent-item:not(:last-child),
    .search-listing-result-item:not(:last-child) {
      margin-bottom: 15px;
      // border-bottom: 1px solid rgba(0 0 0 / .2);
    }

    .search-listing-recent-item {
      padding: 20px !important;
    }

    .search-listing-recent-item,
    .search-listing-result-item {
      padding: 5px 20px;
      background-color: #eee;
      border-radius: 5px;
      cursor: pointer;
    }

    .search-listing-recent-item-title,
    .search-listing-result-item-title {
      color: #333;
    }

    .search-listing-recent-item-close,
    .search-listing-recent-item-close:hover {
      color: var(--iq-bg-dark-bg);
    }

    .search-listing-result-item {
      img {
        width: 60px;
        min-height: 60px;
        object-fit: cover;
        margin-inline-end: 20px;
        border-radius: 50%;
      }

      .search-listing-result-item-title {
        margin-bottom: 10px;
      }
      .search-listing-result-item-category {
        .t {
          margin-inline-end: 10px;
        }
      }
    }
  }
  .search-footer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    .show-more-btn {
      //margin-inline-start: 100px;
      cursor: pointer;
      color: #333;
      font-weight: bold;
      //line-height: 0;
      margin-bottom: 1rem;
    }
  }
  .close-search-btn {
    border-radius: 4px !important;
  }
}
</style>
