<template>
  <b-modal v-model="modalLocation" centered>
    <template #modal-title>
      <h6 class="text-primary">
        {{ $t("main.choose_location") }}
      </h6>
    </template>
    <div>
      <b-row class="mt-4 mb-4">
        <b-col cols="12">
          <main-select
            :reduce="(i) => i.id"
            :options="allCities"
            class="bg-select"
            :labelTitle="$t('main.city')"
            :placeholder="$t('main.city')"
            v-model="city_id"
            label="name"
          >
          </main-select>
        </b-col>
        <b-col cols="12" class="mt-4">
          <main-select
            :reduce="(i) => i.id"
            :options="allAreas"
            class="bg-select"
            :labelTitle="$t('main.area')"
            :placeholder="$t('main.area')"
            v-model="area_id"
            label="name"
          />
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="primary" class="w-100 py-3" @click="saveLocation">
          {{ $t("main.save") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import getCities from "@/mixins/getCities";
// eventBus
import EventBus from "../eventBus.js";

export default {
  mixins: [getCities],

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      all: {
        id: null,
        name: this.$t("main.all"),
      },
    };
  },
  computed: {
    modalLocation: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    allCities() {
      return [this.all, ...this.cities];
    },
    allAreas() {
      return [this.all, ...this.areas];
    },
  },
  watch: {
    modalLocation: {
      handler(value) {
        if (value) {
          this.city_id =
            JSON.parse(localStorage.getItem("city_id")) == "all"
              ? null
              : JSON.parse(localStorage.getItem("city_id")) || null;
          this.area_id =
            JSON.parse(localStorage.getItem("area_id")) == "all"
              ? null
              : JSON.parse(localStorage.getItem("area_id")) || null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    saveLocation() {
      const city_id = this.city_id == null ? "all" : this.city_id;
      const area_id = this.area_id == null ? "all" : this.area_id;
      localStorage.setItem("city_id", JSON.stringify(city_id));
      localStorage.setItem("area_id", JSON.stringify(area_id));
      this.$emit("input", false);
      EventBus.$emit("changeLocation", {
        city_id: this.city_id,
        area_id: this.area_id,
      });
      // localStorage.setItem("city_id", JSON.stringify(this.city_id));
      // localStorage.setItem("area_id", JSON.stringify(this.area_id));
      // this.$emit("input", false);
      // EventBus.$emit("changeLocation", {
      //   city_id: this.city_id,
      //   area_id: this.area_id,
      // });
    },
  },
};
</script>

<style>
</style>