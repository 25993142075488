<template>
  <div class="main_menu">
    <div class="bg-white">
      <b-container>
        <b-row>
          <div
            class="d-flex py-0 justify-content-between align-items-center main-menu__wrapper w-100"
          >
            <div class="d-flex align-items-center">
              <router-link
                tag="a"
                :to="{ path: '/' }"
                class="p-0 logo cursor-pointer"
              >
                <b-img
                  class=""
                  :src="require('@/assets/images/Souq-Alattara-logo.svg')"
                  width="170"
                ></b-img>
              </router-link>
              <div>
                <ul class="m-0 p-0 d-flex justify-content-start gap_1">
                  <router-link
                    v-for="(item, i) in mainMenu"
                    :key="i"
                    :to="{ name: item.pathName }"
                    tag="a"
                    class="cursor-pointer"
                    style="text-transform: capitalize"
                    ><span class="text-primary">{{
                      $t(item.name)
                    }}</span></router-link
                  >
                </ul>
              </div>
            </div>
            <div class="d-flex align-items-center gap_2">
              <search class="flex-grow-1" style="min-width: 300px" />
              <div class="d-flex align-items-center">
                <div class="actions_nav">
                  <ul class="ml-auto navbar-list d-flex align-items-center">
                    <li
                      class="select-lang text-black d-flex bg-white rounded py-1 px-2 nav-item"
                      :class="
                        selectLangPopUp
                          ? 'bg-white text-black'
                          : 'bg-transparent text-white'
                      "
                      @click="selectLangPopUp = !selectLangPopUp"
                    >
                      <ul class="d-flex flex-column align-items-center p-0">
                        <li
                          class="d-flex justify-items-center align-items-center text-center"
                        >
                          <img
                            :src="selectedLang.image"
                            style="
                              width: 25px;
                              height: 15px;
                              margin-inline-end: 5px;
                            "
                            alt=""
                            class="border"
                          />
                          <span>{{ $t(selectedLang.title) }}</span>
                          <i
                            class="las mx-2"
                            :class="
                              selectLangPopUp ? 'la-angle-up' : 'la-angle-down'
                            "
                          ></i>
                        </li>
                        <li
                          class="border-top"
                          :class="!selectLangPopUp ? 'd-none' : 'd-block'"
                          @click="
                            rtlChange(notSelectedLang[0].rtl);
                            langChange(notSelectedLang[0]);
                          "
                        >
                          <span style="font-size: 13px">{{
                            $t(notSelectedLang[0].title)
                          }}</span>
                          <img
                            :src="notSelectedLang[0].image"
                            style="
                              width: 22px;
                              height: 12px;
                              margin-inline-start: 5px;
                            "
                            alt=""
                            class="border"
                          />
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item custom-border">
                      <button
                        @click="modalLocation = true"
                        class="bg-transparent border-0"
                      >
                        <i class="las la-map-marker font-size-22"></i>
                      </button>
                    </li>
                    <li class="nav-item custom-border">
                      <router-link
                        :to="{ name: 'cart' }"
                        class="search-toggle iq-waves-effect language-title cart"
                      >
                        <i
                          class="las la-shopping-cart font-size-22 position-relative"
                        ></i>
                        <div
                          class="d-flex justify-content-end position-absolute cart-count"
                          v-if="getProductsCart"
                        >
                          <span
                            class="number-store-cart d-flex align-items-center justify-content-center"
                            >{{ getProductsCart }}</span
                          >
                        </div>
                      </router-link>
                    </li>
                    <li class="nav-item mx-2" v-if="getUserInfo">
                      <router-link
                        tag="span"
                        :to="{ name: 'user-marketplace' }"
                        class="search-toggle iq-waves-effect language-title"
                        style="cursor: pointer"
                      >
                        <span class="text-black font-weight-normal">
                          {{ getUserInfo.name }}
                        </span>
                        <i
                          class="las la-user text-black font-weight-normal font-size-22"
                        ></i>
                      </router-link>
                    </li>
                    <li class="nav-item bg-primary p-2 rounded-4 mx-2" v-else>
                      <router-link
                        tag="span"
                        :to="{ name: 'auth' }"
                        class="search-toggle iq-waves-effect language-title cursor-pointer"
                      >
                        <span class="">
                          {{ $t("main.login") }}
                        </span>
                        <i class="las la-user font-size-22"></i>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </b-row>
      </b-container>
    </div>
    <AreaModal v-model="modalLocation" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import search from "./Search.vue";
import AreaModal from "../../../AreaModal.vue";
export default {
  components: { search, AreaModal },
  props: ["mainMenu"],
  data() {
    return {
      search: "",
      rtl: false,
      selectLangPopUp: false,
      modalLocation: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedLang: "Setting/langState",
      langsOptions: "Setting/langOptionState",
      getUserInfo: "getUserInfo",
    }),
    notSelectedLang() {
      return this.langsOptions.filter(
        (lng) => lng.title !== this.selectedLang.title
      );
    },
    getProductsCart() {
      return this.$store.getters["cart/getTotalProduct"];
    },
  },
  methods: {
    langChange(lang) {
      this.langChangeState(lang);
      this.$i18n.locale = lang.value;
      localStorage.setItem("lang", lang.value);
      window.location.reload();
    },
    rtlChange(mode) {
      this.$nextTick(() => {
        this.rtl = mode;
        this.modeChange({ rtl: mode, dark: this.darkMode });
      });
    },
    checkArea() {
      const city = JSON.parse(localStorage.getItem("city_id"));
      const area = JSON.parse(localStorage.getItem("area_id"));
      if (area && city) {
        this.modalLocation = false;
      } else {
        this.modalLocation = true;
      }
    },

    ...mapActions({
      langChangeState: "Setting/setLangAction",
      rtlAdd: "Setting/setRtlAction",
      rtlRemove: "Setting/removeRtlAction",
      modeChange: "Setting/layoutModeAction",
    }),
  },
  watch: {},
  created() {
    this.checkArea();
  },
};
</script>

<style lang="scss">
li {
  color: var(--iq-primary);
}
.logo {
  margin: 0 !important;
  margin-left: 1.5rem !important;
  &:lang(en) {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
}
.join-btn {
  transition: 0.3s ease-in-out;
  background-color: var(--iq-warning);
  color: white;
  border: 1px solid transparent;

  &:hover {
    background-color: transparent;
    border-color: #fff;
  }
}

.cart-count {
  top: 5px;
  left: 25px;
  &:lang(en) {
    left: auto;
    right: 25px !important;
  }
}

.number-store-cart {
  background: #d62020;
  border-radius: 50%;
  font-size: 12px;
  width: 20px;
  height: 20px;
  color: #fff;
  font-weight: bold;
}

/*.main-menu__wrapper{*/
/*  width: 85%;*/
/*  margin: auto;*/
/*}*/
.select-lang {
  transition: 0.3s ease-in-out;
}

.select-lang li {
  cursor: pointer;
}

.user-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;
}
@media (min-width: 990px) and (max-width: 1020px) {
  .main-menu__wrapper {
    width: 96% !important;
    margin: auto;
    zoom: 77%;
  }
}

@media (min-width: 1021px) and (max-width: 1400px) {
  .main-menu__wrapper {
    width: 96% !important;
    margin: auto;
    zoom: 79%;
  }
}

/*@media (min-width: 1401px){*/
/*  .main-menu__wrapper {*/
/*    width: 90% !important;*/
/*    margin: auto;*/
/*  }*/
/*}*/
.logoText {
  font-weight: bolder;
}

/*new classes*/

.w-100 {
  width: 100%;
}

.custom-border {
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 25px;
    background: #eeeeee;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }

  &:after {
    left: auto;
    right: 0;
  }
}

//.cart{
//  padding: 0 !important;
//}
.hold-cart {
  margin: 0 1rem;
}

.rounded-4 {
  border-radius: 4px;
}
</style>
