import { singleSignOn } from '@/axios';
export default {
  data () {
    return {
      city_id: null,
      area_id: null,
      cities: [],
      areas: [],
    };
  },
  methods: {
    async getCities () {
      const { data } = await singleSignOn().get('cities');
      this.cities = data.data;
    },
    async getAreas (cityId) {
      const { data } = await singleSignOn().get(`areas?city_id=${ cityId }`);
      this.areas = data.data;
    }
  },
  watch: {
    city_id: {
      handler: function (val) {
        if (val) {
          this.area_id = null;
          this.getAreas(val);
        }

      },
      deep: true,
    }
  },
  created () {
    this.getCities();
    if (this.city_id) {
      this.getAreas(this.city_id);
    }

  }
};