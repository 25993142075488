<template>
  <nav>
    <div class="nav-desketop">
      <main-menu :main-menu="mainMenuItems" />
      <!--      <second-menu :second-menu="secondMenuItems" />-->
    </div>
    <mobile-nav :listItems="[...mainMenuItems]" />
  </nav>
</template>

<script>
import MobileNav from "./MobileNav/MobileNav.vue";
import MainMenu from "./NavDesketop/MainMenu.vue";
// import cartFunctions from '@/mixins/cartFunctions'
// import SecondMenu from './NavDesketop/SecondMenu.vue'
export default {
  components: { MobileNav, MainMenu },

  data() {
    return {
      mainMenuItems: [
        { name: "endUser.home", pathName: "home" },
        { name: "endUser.aboutUs", pathName: "aboutUs" },
        { name: "endUser.contactUs", pathName: "contactUs" },
        { name: "joining.joiningServices", pathName: "registrationProvider" },
      ],
      secondMenuItems: [
        {
          name: "marketplace.stores",
          pathName: "stores",

          icon: `<svg
            fill="white"
            id="store"
            xmlns="http://www.w3.org/2000/svg"
            width="20.341"
            height="17.799"
            viewBox="0 0 20.341 17.799"
          >
            <><g id="Group_134" data-name="Group 134" transform="translate(0 0)">
              <g id="Group_133" data-name="Group 133">
                <path
                  id="Path_118"
                  data-name="Path 118"
                  d="M20.322,37.567l-1.151-4.6A1.27,1.27,0,0,0,17.938,32H2.4a1.273,1.273,0,0,0-1.234.962l-1.151,4.6A.622.622,0,0,0,0,37.721,3.105,3.105,0,0,0,3.019,40.9,2.967,2.967,0,0,0,5.4,39.67a2.926,2.926,0,0,0,4.767,0,2.926,2.926,0,0,0,4.767,0A2.965,2.965,0,0,0,17.322,40.9a3.105,3.105,0,0,0,3.019-3.178A.622.622,0,0,0,20.322,37.567Z"
                  transform="translate(0 -32)" />
              </g>
            </g><g
              id="Group_136"
              data-name="Group 136"
              transform="translate(1.271 9.423)"
            >
                <g id="Group_135" data-name="Group 135">
                  <path
                    id="Path_119"
                    data-name="Path 119"
                    d="M48.051,269.932a4.159,4.159,0,0,1-2.384-.748,4.278,4.278,0,0,1-4.767,0,4.278,4.278,0,0,1-4.767,0,4.127,4.127,0,0,1-4.132.352v6.752a1.272,1.272,0,0,0,1.271,1.271h5.085v-5.085h5.085v5.085h5.085a1.272,1.272,0,0,0,1.271-1.271v-6.752A4.125,4.125,0,0,1,48.051,269.932Z"
                    transform="translate(-32 -269.184)" />
                </g>
              </g></>
          </svg>`,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.nav-desketop {
  display: none;
}
@media (min-width: 992px) {
  .nav-desketop {
    display: block;
  }
  .mobile-nav {
    display: none;
  }
}
</style>
