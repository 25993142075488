<template>
  <div class="bottom-bar py-1">
    <ul class="d-flex justify-items-between align-items-center p-0 mb-0">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: $route.path === tab.route }"
        @click="$router.push(tab.route)"
      >
        <i :class="`las la-${tab.icon}`"></i>
        <span class="d-block text-center">{{ tab.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          name: "المتاجر",
          icon: "store",
          route: "/",
        },
        {
          name: "المفضلة",
          icon: "heart",
          route: "/user-profile/favourite",
        },
        {
          name: "حسابي",
          icon: "user",
          route: "/user-profile/user-settings",
        },
        {
          name: "السلة",
          icon: "shopping-cart",
          route: "/cart",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  box-shadow: 0px -1px 5px 0px #0000001a;
  z-index: 999;
  ul {
    li {
      flex: 1;
      padding: 0.5rem 0;
      color: #a5a5a5;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      i {
        font-size: 24px;
      }
      &.active {
        color: var(--iq-primary);
      }
    }
  }
}
</style>
